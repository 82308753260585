import React from 'react'
import { Link, navigate } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Pagination, Typography, Grid, Card, Box, Hidden, Divider } from '@mui/material'
import { CatIcon, DateIcon, ReadIcon } from '../components/Icons'
import CategoryTag from '../components/CategoryTag'
import { styles, formatDate } from '../utils'
import Seo from '../components/Seo'
import Layout from '../layout'

const Blog = props => {
  const { pageContext } = props
  const { group, pageCount, index, pathPrefix, category } = pageContext

  const onPageChange = (e, p) => {
    const page = p === 1 ? '' : p
    navigate(`/${pathPrefix}/${page}`)
  }

  return (
    <Layout>
      <Seo title={category} />
      {group.map(post => (
        <Card sx={{ marginBottom: 4 }} elevation={8} key={post.id}>
          <Box component='article' sx={styles.articleTag}>
            <Link to={`/${post.slug}`} style={{ marginBottom: '10px' }}>
              <Typography variant='h2' sx={styles.archiveTitle}>
                {post.title}
              </Typography>
            </Link>
            <Grid container spacing={2} sx={{ ...styles.flexStartJustify, mb: 1 }}>
              <Grid item sx={styles.flexCenter}>
                <DateIcon />
                <Typography variant='caption' sx={{ display: 'inline-block' }}>
                  {formatDate(post.date)}
                </Typography>
              </Grid>
              <Grid item sx={styles.flexStartJustify}>
                <CatIcon />
                <Grid container spacing={1} sx={styles.flexStartJustify}>
                  {post.categories.nodes.map((c, i) => {
                    return <CategoryTag name={c.name} id={i} slug={c.slug} />
                  })}
                </Grid>
              </Grid>
              <Grid item sx={styles.flexCenter}>
                <ReadIcon />
                <Typography variant='caption'>{`${post.seo.readingTime} Min Read`}</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ width: '100%' }} />
            <Grid container spacing={2} sx={styles.flexCenter}>
              <Hidden mdDown>
                <Grid lg={2} item sx={styles.flexCenter}>
                  <GatsbyImage image={getImage(post?.featuredImage?.node?.localFile)} alt={post.title} />
                </Grid>
              </Hidden>
              <Grid lg={10} item sx={styles.flexCenter}>
                <Typography
                  components='div'
                  variant='body1'
                  dangerouslySetInnerHTML={{
                    __html: post.excerpt
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      ))}
      <div sx={styles.paginationContainer}>
        <Pagination count={pageCount} page={index} shape='rounded' onChange={onPageChange} color='secondary' size='large' variant='outlined' />
      </div>
    </Layout>
  )
}

export default Blog
